@import "~bootstrap/scss/bootstrap";

html {
    scroll-behavior: smooth;
}

* {
    scroll-margin-top: 80px;
  }

.dropdown-menu.show {
    background-color: black;
}

.bg-dark {
    background-color: black;
}


/* Counter header section : Head area css */
.head-area {
    position : relative;
  }
  .head-area .head-content {
    height : 100vh;
    color : #FFFFFF;
    z-index : 6;
    padding-top : 5.125rem;
    background-image: url('../cube-bg.svg');
    // background-repeat: no-repeat;
    // background-size: cover;
    background-color: black;
    // background-position: center;
  }
  .head-area .head-content::after {
    content : '';
    height : 436px;
    width : 899px;
    position : absolute;
    right : 0;
    top : 30%;
  }
  .head-area .head-content .container {
    z-index : 9;
  }
  .head-area .head-content h1 {
    color : #FFFFFF;
    font-size : 2.8125rem;
    font-weight : 700;
  }
  .head-area .head-content h3 {
    font-weight : 300;
    color : #FFFFFF;
    font-size : 2rem;
  }
  .head-area .head-content h3 strong {
    font-weight : 700;
  }