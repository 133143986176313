
    @font-face {
      font-family: 'Metropolis Regular';
      font-style: normal;
      font-weight: normal;
      src: local('Metropolis Regular'), url('./fonts/Metropolis-Regular.woff') format('woff');
      }
      
  
    @font-face {
      font-family: 'Metropolis Regular Italic';
      font-style: normal;
      font-weight: normal;
      src: local('Metropolis Regular Italic'), url('./fonts/Metropolis-RegularItalic.woff') format('woff');
      }
      
  
    @font-face {
      font-family: 'Metropolis Extra Light';
      font-style: normal;
      font-weight: normal;
      src: local('Metropolis Extra Light'), url('./fonts/Metropolis-ExtraLight.woff') format('woff');
      }
      
  
    @font-face {
      font-family: 'Metropolis Light';
      font-style: normal;
      font-weight: normal;
      src: local('Metropolis Light'), url('./fonts/Metropolis-Light.woff') format('woff');
      }
      
  
    @font-face {
      font-family: 'Metropolis Thin';
      font-style: normal;
      font-weight: normal;
      src: local('Metropolis Thin'), url('./fonts/Metropolis-Thin.woff') format('woff');
      }
      
  
    @font-face {
      font-family: 'Metropolis Extra Light Italic';
      font-style: normal;
      font-weight: normal;
      src: local('Metropolis Extra Light Italic'), url('./fonts/Metropolis-ExtraLightItalic.woff') format('woff');
      }
      
  
    @font-face {
      font-family: 'Metropolis Light Italic';
      font-style: normal;
      font-weight: normal;
      src: local('Metropolis Light Italic'), url('./fonts/Metropolis-LightItalic.woff') format('woff');
      }
      
  
    @font-face {
      font-family: 'Metropolis Thin Italic';
      font-style: normal;
      font-weight: normal;
      src: local('Metropolis Thin Italic'), url('./fonts/Metropolis-ThinItalic.woff') format('woff');
      }
      
  
    @font-face {
      font-family: 'Metropolis Medium';
      font-style: normal;
      font-weight: normal;
      src: local('Metropolis Medium'), url('./fonts/Metropolis-Medium.woff') format('woff');
      }
      
    @font-face {
      font-family: 'Metropolis Semi Bold';
      font-style: normal;
      font-weight: normal;
      src: local('Metropolis Semi Bold'), url('./fonts/Metropolis-SemiBold.woff') format('woff');
      }
      
  
    @font-face {
      font-family: 'Metropolis Bold';
      font-style: normal;
      font-weight: normal;
      src: local('Metropolis Bold'), url('./fonts/Metropolis-Bold.woff') format('woff');
      }
      
  
    @font-face {
      font-family: 'Metropolis Bold Italic';
      font-style: normal;
      font-weight: normal;
      src: local('Metropolis Bold Italic'), url('./fonts/Metropolis-BoldItalic.woff') format('woff');
      }
      
  
    @font-face {
      font-family: 'Metropolis Medium Italic';
      font-style: normal;
      font-weight: normal;
      src: local('Metropolis Medium Italic'), url('./fonts/Metropolis-MediumItalic.woff') format('woff');
      }
      
  
    @font-face {
      font-family: 'Metropolis Semi Bold Italic';
      font-style: normal;
      font-weight: normal;
      src: local('Metropolis Semi Bold Italic'), url('./fonts/Metropolis-SemiBoldItalic.woff') format('woff');
      }
      
  
    @font-face {
      font-family: 'Metropolis Extra Bold';
      font-style: normal;
      font-weight: normal;
      src: local('Metropolis Extra Bold'), url('./fonts/Metropolis-ExtraBold.woff') format('woff');
      }
      
  
    @font-face {
      font-family: 'Metropolis Extra Bold Italic';
      font-style: normal;
      font-weight: normal;
      src: local('Metropolis Extra Bold Italic'), url('./fonts/Metropolis-ExtraBoldItalic.woff') format('woff');
      }
      
  
    @font-face {
      font-family: 'Metropolis Black';
      font-style: normal;
      font-weight: normal;
      src: local('Metropolis Black'), url('./fonts/Metropolis-Black.woff') format('woff');
      }
      
  
    @font-face {
      font-family: 'Metropolis Black Italic';
      font-style: normal;
      font-weight: normal;
      src: local('Metropolis Black Italic'), url('./fonts/Metropolis-BlackItalic.woff') format('woff');
      }



      body {
        margin: 0;
        font-family: 'Metropolis Regular', sans-serif !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }

.App {
  text-align: center;
  font-family: 'Metropolis Regular';
}

.center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  }

.logo {
  float: left;
  width: 120px;
  margin-left: 30px;
  margin-right: 30px;
}

a.nav-link {
  color: white;
}

.manifesto.p {
  color: green;
}